<template>
    <div class="mfp-bg mfp-newsletter mfp-fadein-popup mfp-ready"></div>
    <div class="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-login-popup mfp-fade mfp-ready" tabindex="-1"
        style="overflow: hidden auto;">
        <div class="mfp-container mfp-ajax-holder">
            <div class="mfp-content">
                <div class="login-popup otp-modal  white-popup">
                    <div class="mb-6">
                        <h4 class="pl-1 mb-1 text-blue">Email Verification</h4>
                        <p class="pl-1 mb-1 text-blue">Enter the code we just send on your email</p>
                        <p class="pl-1" style="text-transform:none"><span class="active-filter">{{this.email}}</span></p>
                    </div>
                    <div style="display: flex; flex-direction: row;" class="mb-6">
                        <OtpInput class="text-blue"
                            ref="otpInput"
                            input-classes="otp-input"
                            separator="-"
                            :num-inputs="6"
                            :should-auto-focus="true"
                            :is-input-num="true"
                            @on-change="handleOnChange"
                            @on-complete="handleOnComplete"
                            />
                    </div>
                    <div style="text-align:center">
                        <p class="mb-1">Don't receive the code?</p>
                        <p><span class="active-filter" style="cursor:pointer" @click="resend()">Resend</span></p>
                    </div>
                    
                    <button title="Close (Esc)" type="button" class="mfp-close" @click="$emit('closeOtp')">×</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import OtpInput from 'vue3-otp-input'
    import Helpers from '@/mixins/Helpers'

    export default {
        props: ['type', 'email'],
        components: {
            OtpInput,
        },

        methods: {
            handleOnComplete(value) {
                var data = {
                    email: this.email,
                    otp: value,
                    type: this.type
                }
                this.$http.post(`api/verify-otp`, data)
                    .then(response => {
                        if(this.type == "registration") {
                            localStorage.setItem('accessToken', response.data.data.access_token);
                            this.$store.commit('authUser', {
                                user: response.data.data
                            })
                        } else if (this.type == 'login') {
                            this.$store.commit('authUser', {
                                user: response.data.data
                            })
                        } else {
                            this.emitter.emit('reset-password', {
                                otp: data.otp
                            })
                        }
                        this.emitter.emit("close", {
                            user: response.data.data
                        });
                        this.$toast.success('Otp verified')
                    })
                    .catch(e => {
                        this.$toast.error(e.response.data.message)
                    })
                
            },

            resend() {
                var data = {
                    email: this.email,
                    type: this.type
                }
                this.$http.post(`api/resend-email`, data)
                    .then(response => {
                        this.$toast.success(response.data.message)
                    })
                    .catch(e => { 
                        this.$toast.error(e.response.data.message)
                    })
            }

        },

        mixins: [Helpers]

    }
</script>

<style>

</style>