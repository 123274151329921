<template>
    <div class="mfp-bg mfp-newsletter mfp-fadein-popup mfp-ready"></div>
    <div class="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-login-popup mfp-fade mfp-ready" tabindex="-1"
        style="overflow: hidden auto;">
        <div class="mfp-container mfp-ajax-holder">
            <div class="mfp-content">
                <div class="login-popup otp-modal white-popup" style="width:max-content">
                    <div class="mb-6">
                        <h4 class="pl-1 mb-1 text-blue">Reset Password</h4>
                    </div>
                    <div style="width:400px">
                        <form v-if="form" @submit.prevent="submit()" ref="form">
                            <Error class="mt-1" v-if="errors" :message="errors"></Error>
                            <div class="form-group pl-1" style="font-size: 1.4rem">
                                <label>New Password *</label>
                                <input type="password" class="form-control text-blue" v-model="form.new_password" id="passowrd">
                            </div>
                            <div class="form-group pl-1" style="font-size: 1.4rem">
                                <label>Confirm Password *</label>
                                <input type="password" class="form-control text-blue" v-model="form.password_confirmation" id="password">
                            </div>
                            <div class="d-flex justify-content-end">
                                <input class="btn btn-primary mr-1 bg-orange btn-rounded" 
                                            :class="{ 'opacity-25': form.processing}" :disabled="form.processing" type="submit" value="Reset">
                            </div>
                        </form>
                    </div>
                    <button title="Close (Esc)" type="button" class="mfp-close"
                        @click="$emit('closeResetPassword')">×</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Helpers from '@/mixins/Helpers'
    import Error from './InputError.vue'
    export default {
        props: ['otp'],
        components: {
            Error
        },
        data() {
            return {
                form: {
                    otp: this.otp,
                    new_password: null,
                    password_confirmation: null
                },
                errors: null
            }
        },

        methods: {
            submit() {
                this.form.processing = true
                this.$http.post(`api/reset-password`, this.form).
                    then(response => {
                        this.$toast.success(response.data.message)
                        this.$emit('closeResetPassword')
                    }).catch(e => {
                        this.errors = e.response.data.message
                        this.form.processing = false
                    })
            }
        },

        mixins: [Helpers]

    }
</script>

<style>

</style>