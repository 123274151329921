<template>
    <div class="input-wrapper mb-7  mt-5"
        style="
        border-radius: 8px;
        height: 34px; 
        border: 2px solid #f5f5f5;
        margin: auto;
        min-width: 100%;"
        :class="{ 'header-search hs-expanded hs-round d-none d-md-flex': mobile == null }">
        <div class="input-group-prepend d-flex align-items-center">
            <div 
                class="
                    tag-item 
                    text-center 
                    bg-orange 
                    mx-1 
                    border-orange 
                    rounded 
                    orange-shadow 
                    d-flex 
                    align-items-center 
                    mx-0" 
                style="
                    padding-left: 8px;
                    padding-right: 2px;"
                @click="removeFirstTag"
                v-if="firstElement"
            >
                <p class="mb-0 tag-text fs-1 text-white" >{{ firstElement.name }}</p>
                <i class="w-icon-times-solid fs-1 text-white text- px-2 cursor-pointer"/>
            </div>
            <div class="d-flex" v-for="(tag, index) in selectedTag" :key="index">
                <p class="m-0 text-orange"><span v-if="firstElements && selectedTag.length > 0 || index > 0"><i class="w-icon-minus" /></span></p>
                <div class="
                        tag-item 
                        text-center 
                        bg-orange 
                        mx-1 
                        border-orange 
                        rounded 
                        orange-shadow 
                        d-flex 
                        align-items-center 
                        mx-1 
                        mx-0"  
                        style="
                            padding-left: 8px;
                            padding-right: 2px;"
                    >
                <p class="mb-0 tag-text fs-1 text-white" >{{ tag.name }}</p>
                <i class="w-icon-times-solid fs-1 text-white text- px-2 cursor-pointer" @click="removeTag(index)"/>
            </div>

            </div>
        </div>
        <input type="text" 
            style="border:none; padding:0px; min-height: 100%;"
            class="form-control text-center search-bar p-0 m-0" v-on:keyup.enter="serarchProducts()" v-model="search" 
            placeholder="Search by industry, business, category or product " />
        <button 
        style="margin-right: 7px!important;
        height: 27px!important;
        min-width: 26px!important;
        margin-bottom: auto !important;
        margin-top: auto !important;"
        class="btn btn-search m-0" 
        @click="serarchProducts()">
        <i class="w-icon-search"  
        style="font-size: 12px;"
        autocomplete="search"></i>
        </button>
    </div>
</template>

<script>
import Helpers from '@/mixins/Helpers'
    export default {
        props: ['mobile', 'selectedTags', 'firstElement'],
        data() {
            return {
                search: '',
                selectedTag: this.selectedTags,
                firstElements: this.firstElement,
                route: this.$route
            }
        },
        methods: {
            serarchProducts() {
                if(this.search) {
                    this.$router.push('/products/'+this.search)
                } else {
                    this.$router.push('/shop')
                }
            },
            removeTag(index){
                let itemDelete = this.selectedTag.length - index
                // let tag = this.selectedTag[index];
                this.selectedTag.splice(index, itemDelete)
                this.emitter.emit('tag-added', {
                    tags: this.selectedTag
                })
                if(this.route.fullPath.includes('vendor-store')){
                    this.$router.push(`/vendor-store/${this.route.params.slug}/
                    ${this.selectedTag.length > 0 ? this.selectedTag[0].slug + '/' : ''}
                    ${this.selectedTag.length > 1 ? this.selectedTag[1].slug + '/'  : ''}`
                    .replace(/\s+/g, ''))
                }else{
                    this.$router.push(`/
                    ${this.selectedTag.length > 0 ? this.selectedTag[0].slug + '/' : ''}
                    ${this.selectedTag.length > 1 ? this.selectedTag[1].slug + '/'  : ''}
                    ${this.selectedTag.length > 2 ? this.selectedTag[2].slug + '/'  : ''}`
                    .replace(/\s+/g, ''))
                }
                
            },
            removeFirstTag(){
                this.emitter.emit('tag-added-without-processing', {
                    tag: null
                })
                this.emitter.emit('empty-tags')

                this.$router.push('/');
            }
        },
        async created() {
            // if(this.$route.params.slug && this.$route.params.levelThreeslug && !this.firstElements && this.$route.fullPath.includes('vendor-store')){
            //     console.log("If")
            //     await this.$http.get(`api/${this.getModuleValue()}/businesses/${this.$route.params.slug}`, {
            //     }).then(response => {
            //         this.vendorStore = response.data.data
            //         this.loading = false;
            //         this.emitter.emit('tag-added-without-processing', {
            //             tag: { name: this.vendorStore.name}
            //         })
            //         this.emitter.emit('business-name', {
            //             business: this.vendorStore.name
            //         })
            //     })
            //     .catch(e => {
            //         console.log(e)
            //         this.errors = e.response.data.message
            //     })
            // }
            // this.$watch(
            //     () => this.$route,
            //         async (route) => {
            //             this.route = route
            //             // console.log("as")
            //             // if(params.slug && params.levelThreeslug && !this.firstElement && this.$route.fullPath.includes('vendor-store')){
            //                 // await this.$http.get(`api/${this.getModuleValue()}/businesses/${this.$route.params.slug}`, {
            //                 // })
            //                 // .then(response => {
            //                 //     this.vendorStore = response.data.data
            //                 //     this.loading = false;
            //                 //     this.emitter.emit('tag-added-without-processing', {
            //                 //         tag: { name: this.vendorStore.name}
            //                 //     })
            //                 //     this.emitter.emit('business-name', {
            //                 //         business: this.vendorStore.name
            //                 //     })
            //                 // })
            //                 // .catch(e => {
            //                 //     console.log(e)
            //                 //     this.errors = e.response.data.message
            //                 // })
            //             // }
            //         },
            //     { immediate: true }
            // )
            this.emitter.on("clear-search", item => {
                this.selectedTag = []
            });
        },
        watch: {
            selectedTags:{
                handler(tags){
                    this.selectedTag = tags.filter(n => n)
                },
                deep: true
            }, 
            firstElement: {
                handler(element){
                    this.firstElements = element
                }
            },
            // '$route': {
            //     handler(route){
            //         console.log(route)
            //         if(this.route.params.slug && route.params.levelThreeslug && !this.firstElement && this.route.fullPath.includes('vendor-store')){
            //             console.log("If")
            //         }
            //     }
            // }
        },
        mixins: [Helpers]
    }
</script>

<style>
.search-bar::-moz-placeholder{
    color: #ccc
}
.btn-search{
    background: #fe7c00!important;
    min-width: 32px!important;
    height: 34px!important;
    border-radius: 20px!important;
    margin-top: 7px!important;
    margin-right: 10px!important;
    color:white!important;
}
</style>