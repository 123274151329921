<template>
  <div class="page-wrapper">
    <v-app>
        <!-- <router-view v-slot="{ Component, route}"> -->
            <!-- <component v-if="route.name == 'login'" :is="Component" /> -->
        <router-view v-slot="{ Component}">
            <div>
                <Header />
                <main class="main d-flex" style="min-height: 50vh;">
                    <login @close="closeModal" :type="formType"></login>
                    <forgot-password v-if="forgotPassword" @closeForgotPwd="closeForgotPassword"></forgot-password>
                    <otp-modal v-if="showOtp" @closeOtp="closeOtpModal" :type="formType" :email="email"></otp-modal>
                    <ResetPassword v-if="showResetPassword" @closeResetPassword="closeResetPassword" :otp="otp"></ResetPassword>
                    <!-- <div class="left-content">
                    </div> -->
                    <div class="main-content">
                        <component :is="Component" />
                    </div>
                    <!-- <div class="right-content">
                        
                    </div> -->
                </main>
                <Footer />
            </div>
        </router-view>
    </v-app>
        
  </div>
    <!-- Start of Sticky Footer -->
    <div class="sticky-footer sticky-content fix-bottom">
        <router-link to="/" @click="clearSearch()" class="sticky-link" :class="{ 'active': $route.path == '/' }">
            <i class="w-icon-home"></i>
            <p>Home</p>
        </router-link>
        <router-link  @click="clearSearch()" to="shop" class="sticky-link" :class="{ 'active': $route.path == '/shop' }">
            <i class="w-icon-category"></i>
            <p>Shop</p>
        </router-link>
        <router-link  @click="clearSearch()" v-if="token" to="/my-account" class="sticky-link">
            <i class="w-icon-account"></i>
            <p>Account</p>
        </router-link>
        <div class="cart-dropdown dir-up">
            <router-link :to="{path: '/cart'}" @click="clearSearch()" class="sticky-link" :class="{ 'active': $route.path == '/cart' }">
                <i class="w-icon-cart"></i>
                <p>Cart</p>
            </router-link>
            <!-- End of Dropdown Box -->
        </div>

        <div class="header-search hs-toggle dir-up">
            <a @click="showSearchBar" class="search-toggle sticky-link">
                <i class="w-icon-search"></i>
                <p>Search</p>
            </a>
            <search-bar :mobile="'yes'" id="search"></search-bar>
        </div>
    </div>
    <ScrollToTop />
    <Newsletter />
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'
import ScrollToTop from './extras/ScrollToTop.vue'
import Newsletter from './extras/Newsletter.vue'
import SearchBar from '@/components/SearchBar.vue'
import Helpers from '@/mixins/Helpers'
import ForgotPassword from '@/components/ForgotPassword.vue'
import OtpModal from '@/components/OtpModal.vue'
import ResetPassword from '@/components/ResetPassword.vue'
import Login from '@/components/Login.vue'
export default {
    components: {
        Header,
        Footer,
        ScrollToTop,
        Newsletter,
        SearchBar,
        ForgotPassword,
        OtpModal,
        ResetPassword,
        Login
  },
  data () {
      return {
          show: false,
          token: null,
          remove: 'no',
          levelTwoTags: [],
          router: this.$route,
          forgotPassword: false,
          showOtp: false,
          email: null,
          formType: 'login',
          showResetPassword: false,
          otp: null,
      }
  },
  methods: {
    showSearchBar() {
        this.show = !this.show
        var el = document.getElementById('search')
        if(this.show) {
            el.style.cssText = 'visibility: visible; opacity: 1; transform: translate3d(0,20px,0)'
        } else {
            el.style.cssText = 'visibility: hidden; opacity: 0'
        }
    },
    clearSearch(){
        this.emitter.emit("clear-search");
    },

    closeForgotPassword() {
        setTimeout(() => {
            this.forgotPassword = false
        }, 500)
    },

    showOtpModal(args) {
        this.email = args.email ? args.email : this.getUserData().email
        this.formType = args.type ? args.type : 'login'
            setTimeout(() => {
                this.showOtp = true
            }, 500)
    },
    closeOtpModal() {
        setTimeout(() => {
            this.showOtp = false
            }, 500)
    },
    closeResetPassword() {
        setTimeout(() => {
            this.showResetPassword = false
        }, 500)
    },
    openModal(type) {
        this.formType = type
            this.emitter.emit('open-login-modal', {
                type: this.formType
            })
        },
    closeModal() {
        setTimeout(() => {
            this.showModal = false
        }, 500)
    },
  },
  beforeMount(){
    this.router = this.$route;
  },
  mounted() {
        this.emitter.on('reset-password', (args) => {
            this.otp = args.otp
            this.showResetPassword = true
        })
      this.emitter.on('close', (args) => {
        if(localStorage.getItem('accessToken') != 'undefined') {
            this.token = localStorage.accessToken
        }
        this.closeOtpModal()
    })
    this.emitter.on('logout', (args) => {
        this.token = null
    })
    if(localStorage.getItem('accessToken') != 'undefined') {
        this.token = localStorage.accessToken
    }

    this.emitter.on('remove-header', () => {
       this.remove = null
    })
    this.emitter.on('forgot-password', () => {
        this.forgotPassword = true
    })
    this.emitter.on('otp', (args) => {
        this.showOtpModal(args)
    })
  },
    created() {
        // empty filters vuex store after 15 minutes
        this.resetStateInterval = setInterval(() => {
            if(this.$route.name != 'LevelFourHomepage') {
                this.$store.dispatch('filters/resetState', {flag: 'general'});
            } else if(this.$route.name != 'Store-level-four') {
                this.$store.dispatch('filters/resetState', {flag: 'store'});
            }
        }, 900000);
    },
    mixins: [ Helpers ]
}
</script>