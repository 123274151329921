<template>
    <!-- Start of Footer -->
    <footer class="footer footer-dark appear-animate mb-0">
        <div class="container">
            <div class="footer-top">
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="widget widget-about">
                            <router-link :to="{ name: 'Home' }" @click="removeStore()" 
                                class="logo-footer">
                                <img style="width:200px" height="45"
                                    :src="getImage('media/images/logo/localverse_white_transparent.png')" alt="logo" />
                            </router-link>
                            <div class="widget-body">
                                <div class="social-icons social-icons-colored">
                                    <a href="#" class="social-icon social-facebook w-icon-facebook"></a>
                                    <a href="#" class="social-icon social-twitter w-icon-twitter"></a>
                                    <a href="#" class="social-icon social-instagram w-icon-instagram"></a>
                                    <a href="#" class="social-icon social-youtube w-icon-youtube"></a>
                                    <a href="#" class="social-icon social-pinterest w-icon-pinterest"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="widget">
                            <h3 class="widget-title">Company</h3>
                            <ul class="widget-body">
                                <li><a href="#">About Us</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="widget">
                            <h4 class="widget-title">My Account</h4>
                            <ul class="widget-body">
                                <li v-if="!module || module == 'retail'">
                                    <a v-if="!isLoggedIn" @click="openLoginModal()">Track My Order</a>
                                    <router-link v-else :to="{path: '/my-account/orders'}">Track My Order</router-link>
                                </li>
                                <li v-if="!module || module == 'retail'"><router-link :to="{path: '/cart'}">View Cart</router-link></li>
                                <li>
                                    <a v-if="!isLoggedIn" @click="openLoginModal()">Sign In</a>
                                    <a v-else @click="logout()">Sign Out</a>
                                </li>
                                <li>
                                    <router-link :to="{path: '/my-account/wishlist'}" v-if="isLoggedIn">My Wishlist</router-link>
                                    <a v-else @click="openLoginModal()">My Wishlist</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="widget">
                            <h4 class="widget-title">Customer Service</h4>
                            <ul class="widget-body">
                                 <li><a href="#">Contact Us</a></li>
                                <li>
                                    <a v-if="!isLoggedIn" @click="openLoginModal()">Payment Methods</a>
                                    <router-link :to="{path: '/my-account/payment-method'}" v-if="isLoggedIn">Payment Methods</router-link>
                                </li>
                                <li><a href="#">Product Returns</a></li>
                                <li><a href="#">Term and Conditions</a></li>
                                <li v-if="getModuleName() == 'automotive'">
                                    <router-link :to="{path: `/${getModuleName()}/reviews`}" >Vehicle Reviews</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-middle" v-if="module && module !== 'general-setting'">
                <div class="widget widget-category" v-for="tag in tags" :key="tag">
                    <div class="category-box" >
                        <h6 class="category-name">
                            <router-link style="font-size: 14px;font-weight: 600;" :to="{ path: '/' + getModuleName() + '/' + tag.slug }">{{ tag.name + ':'}}</router-link>
                        </h6>
                        <router-link v-for="child in tag.childrens" :key="child" :to="{ path: '/' + getModuleName() + '/' + tag.slug + '/' + child.slug }" :class="{ 'disabled-link': tagsClick }">
                            <span v-if="tagsClick">{{ child.name}}</span>
                            <span v-else>{{ child.name}}</span>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="footer-left">
                    <p class="copyright">Copyright © {{ new Date().getFullYear() }} InterApptive.Inc. All Rights Reserved.</p>
                </div>
                <div class="footer-right">
                    <span class="payment-label mr-lg-8">We're using safe payment method</span>
                    <span>Powerd by:</span>
                    <figure class="payment">
                        <img :src="getImage('media/images/stripe-ar21.svg')" alt="payment" width="159" height="25" style="width:65%"/>
                    </figure>
                </div>
            </div>
        </div>
    </footer>
    <!-- End of Footer -->
</template>

<script>
    import Helpers from '@/mixins/Helpers'
    export default {
        mixins: [Helpers],
        data(){
            return {
                tags: null,
                module: this.$route.params && this.$route.params.levelOneslug ? this.$route.params.levelOneslug : null,
                tagsClick: false,
                withDelivery:  this.getFilter(),
                isLoggedIn: localStorage.getItem('accessToken') ? true : false,
            }
        },

        methods: {
            getTags() {
                if(this.module && this.module !== 'general-setting') {
                    this.$http.get(`api/level/two/${this.module}`, {
                    params: {
                        withChildrens: true,
                        limit: 6,
                        header_filter: this.withDelivery
                    }
                    }).then(response => {
                        this.tags = response.data.data
                    }).catch((e) => {
                        console.log(e)
                    }) 
                } else {
                    this.module = null
                }
                
            },

            getFilter() {
                switch (this.module) {
                    case 'retail':
                        return localStorage.getItem('delivery_type') && localStorage.getItem('delivery_type') == 'delivery' ? 'delivery' : 'pick_up';
                    case 'automotive':
                    case 'boats' :
                        return localStorage.getItem('vehicle_type') && localStorage.getItem('vehicle_type') == 'new' ? 'new' : 'used'
                    default:
                        return null
                }
            },
            logout() {
            const token = localStorage.getItem('accessToken')
            this.$http.defaults.headers.common['Authorization'] = `Bearer ${token}`

            this.$http.post('/api/logout', {
                device_token: localStorage.getItem('firebase_token') ? localStorage.getItem('firebase_token') : null
            })
                .then(response => {
                    this.$router.push('/');
                    localStorage.removeItem('vuex');
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('cartData')
                    localStorage.getItem('firebase_token') ? localStorage.removeItem('firebase_token') : null
                    this.emitter.emit("addToCart", {
                    })
                    localStorage.setItem('LS_ROUTE_KEY', this.$route.name)
                    this.emitter.emit('logout')
                    this.$store.dispatch('cart/saveCartCount', 0)
                    this.$toast.success("Logout Successfully")
                })
                .catch(e => {
                    this.errors = e.response.data.message
                    this.inValidToken(e.response.data.message)
                })
        },

            openLoginModal() {
                this.emitter.emit('open-login-modal', {
                    type: 'login'
                })
            }
        },
        mounted() {
            this.getTags();
            this.emitter.on('footer-tags', (args) => {
                this.tags = null;
                this.module = args.module ? args.module : this.$route.params.levelOneslug
                this.withDelivery = this.getFilter();
                this.getTags()
            })
            this.emitter.on('custom-loading', (args) => {
                this.tagsClick = args;
            })
            this.emitter.on('logout', (args) => {
                this.isLoggedIn = false
            })

            this.emitter.on('change-module', (args) => {
                this.tags = null;
                this.module = args.module;
                args.module !== 'general-setting' ?  this.getTags() : null;
            })

            this.emitter.on("headerFilter", (args) => {
                this.tags = null;
                this.withDelivery = args.filterValue;
                this.getTags();
            })

            this.emitter.on('user-logged-in', () => {
                this.isLoggedIn = true
            })
        },
        unmounted() {
            this.emitter.all.delete('change-module');
        },
    }
</script>

<style scoped>
.disabled-link {
  /* Define the styles for the disabled link */
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}
</style>