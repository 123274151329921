const state = {
  user: null 
};

const mutations = {
    authUser (state, payload) {
        state.user = payload
    }
};

const actions = {
  authUser(context, payload) {
    context.commit('authUser', payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};