import { createWebHistory, createRouter } from "vue-router";
import {base_url} from '../composeable/common'

const routes = [
  {
    path: "/",
    meta:{guest:false},
    name: "Home",
    component: () => import('@/views/pages/Home.vue'),
  },
  {
    path: "/:levelOneslug",
    name: "LevelOneHomepage",
    component: () => import('@/views/pages/homepages/LevelOneHome.vue'),
  },
  {
    path: "/:levelOneslug/:levelTwoslug",
    name: "LevelTwoHomepage",
    component: () => import('@/views/pages/homepages/LevelTwoHome.vue'),
  },
  {
    path: "/:levelOneslug/:levelTwoslug/:levelThreeslug",
    name: "LevelThreeHomepage",
    component: () => import('@/views/pages/homepages/LevelThreeHome.vue'),
  },
  {
    path: "/:levelOneslug/:levelTwoslug/:levelThreeslug/:levelFourslug",
    name: "LevelFourHomepage",
    component: () => import('@/views/pages/homepages/LevelFourHome.vue'),
  },
  {
    path: "/shop/:levelTwoslug/:levelThreeslug",
    name: "shops",
    component: () => import('@/views/pages/filters/ShopByProduct.vue'),
  },
  {
    path: "/store/:levelTwoslug/",
    name: "stores",
    component: () => import('@/views/pages/homepages/LevelFourHome.vue'),
  },
  {
    path: "/vendor-store/:levelOneslug/:slug/",
    name: "Store",
    component: () => import('@/views/pages/vendors/Store.vue'),
  },
  {
    path: "/vendor-store/:levelOneslug/:slug/:levelTwoslug",
    name: "Store-level-two",
    component: () => import('@/views/pages/vendors/StoreLevelTwo.vue'),
  },
  {
    path: "/vendor-store/:levelOneslug/:slug/:levelTwoslug/:levelThreeslug",
    name: "Store-level-three",
    component: () => import('@/views/pages/vendors/StoreLevelThree.vue'),
  },
  {
    path: "/vendor-store/:levelOneslug/:slug/:levelTwoslug/:levelThreeslug/:levelFourslug",
    name: "Store-level-four",
    component: () => import('@/views/pages/vendors/StoreLevelFour.vue'),
  },
  {
    path: "/about",
    meta:{guest:false},
    name: "About",
    component: () => import('@/views/pages/Home.vue'),
  },

  {
    path: "/vendors",
    meta:{guest:false},
    name: "Vendors",
    component: () => import('@/views/pages/vendors/Index.vue'),
  },
  {
    path: "/checkout",
    meta:{guest:false},
    name: "Checkout",
    props: true,
    component: () => import('@/views/pages/Checkout/Index.vue'),
  },
  {
    path: "/my-account/:wishlist?",
    meta:{
      requiresAuth: true
    },
    name: "Account",
    component: () => import('@/views/pages/account/Index.vue'),
  },
  {
    path: "/cart",
    meta:{guest:false},
    name: "Cart",
    component: () => import('@/views/pages/cart/ViewCart.vue'),
  },

  {
    path: "/product/:uuid",
    meta:{guest:false},
    name: "Product",
    component: () => import('@/views/pages/products/Product.vue'),
  },

  {
    path: "/shop",
    meta:{guest:false},
    name: "Shop",
    component: () => import('@/views/pages/products/Index.vue'),
  },
  {
    path: "/categories/:slug?",
    meta:{guest:false},
    name: "categories",
    component: () => import('@/views/pages/categories/Index.vue'),
  },

  // {
  //   path: "/products/:query?",
  //   meta:{guest:false},
  //   name: "Search",
  //   component: () => import('@/views/pages/SearchResults/Index.vue'),
  // },

  {
    path: "/search/:levelOneslug?/:levelTwoslug?/:levelThreeslug?/:levelFourslug?",
    meta:{guest:false},
    name: "Search",
    component: () => import('@/views/pages/SearchResults/Search.vue'),
  },
  {
    path: "/department/:slug",
    meta:{guest:false},
    name: "Department",
    component: () => import('@/views/pages/departments/Index.vue'),
  },
  {
    path: "/order/:uuid",
    meta:{guest:false},
    name: "Order",
    component: () => import('@/views/pages/order/Index.vue')
  },
  {
    path: "/login",
    meta:{guest:true},
    name: "login",
    component: () => import('@/views/pages/Login.vue')
  },
  {
    path: "/vendor-detail/:levelOneslug/:slug",
    meta:{guest:false},
    name: "VendorDetail",
    component: () => import('@/views/pages/vendors/StoreDetail.vue')
  },
  {
    path: "/:levelOneslug/reviews",
    name: "Automotive-reviews",
    component: () => import('@/views/pages/reviews/Index.vue'),
  },
  {
    path: "/:levelOneslug/body/styles",
    name: "Body-styles",
    component: () => import('@/views/pages/bodystyles/Index.vue'),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import('@/layouts/errors/Error404.vue'),
  },
  {
    path: "/500",
    name: "500",
    component: () => import('@/layouts/errors/Error404.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  // linkExactActiveClass: "exact-active",
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});

router.beforeEach((to, from, next) => {
  const lastRouteName = localStorage.getItem('LS_ROUTE_KEY')
  const token = localStorage.getItem('accessToken')
  const shouldRedirect = Boolean(
     to.name === "Home" &&
     lastRouteName
  )

  const isLoggedIn = localStorage.getItem('accessToken');
  if (to.name === 'login' && isLoggedIn) {
    next({ name: 'Home' })
  } else {
    next()
  }
  
  if(to.meta.requiresAuth && !token) {
    next({
      path: '/',
    });
  }
  else {
    if (shouldRedirect) {
      next({ name: lastRouteName })
      localStorage.removeItem("LS_ROUTE_KEY");
    } else {
      next()
      localStorage.removeItem("LS_ROUTE_KEY");
    }
  }
})

export default router;