const state = {
    currentPage: 1,
    currentStorePage: 1
}

const mutations = {
    setCurrentPage(state, page) {
      state.currentPage = page
    },
    setCurrentStorePage(state,page) {
        state.currentStorePage = page;
    }
}

const actions = {
    setPage(context, page) {
        context.commit('setCurrentPage', page)
    },
    setStorePage(context,page) {
        context.commit('setCurrentStorePage', page);
    }
}
  
export default {
    namespaced: true,
    state,
    mutations,
    actions,
}