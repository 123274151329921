const state = {
  filters: null,
  headerFilters: [],
  price: null,
  businessFilters: null,
  businessHeaderFilters: [],
  businessPrice: null
};

const mutations = {
    saveFilters (state, payload) {
      if(payload && payload.flag && payload.flag == 'remove') {
        const remainingData = {};
        for (let key in state.filters.filters) {
          remainingData[key] = state.filters.filters[key].filter(item => item !== payload.filter.id);
        }
        let filters = {
          filters : remainingData
        }
        state.filters = filters
      } else if(payload && payload.flag && payload.flag == 'removeAll') {
          if(state.filters && state.filters.filters){
            for (let key in state.filters.filters) {
              state.filters.filters[key].length = 0;
            }
          }
      } else{
        state.filters = payload
      }
    },

    saveHeaderFilters(state, payload) {
      if(payload.flag == 'push') {
        state.headerFilters.push(payload.filter)
      } else if (payload.flag == 'removeAll') {
        state.headerFilters = []
      } 
      else {
        state.headerFilters = state.headerFilters.filter(f => f.id !== payload.filter.id);
      }
   },
    savePrice(state, payload) {
      state.price = payload
    },

    // manage states of business filters

    saveBusinessFilters(state, payload) {
      if(payload && payload.flag && payload.flag == 'remove') {
        for (let key in state.businessFilters.filters) {
          if (key === payload.filter.title) {
            const remainingData = state.businessFilters.filters[key].filter(item => item !== payload.filter.id);
            state.businessFilters.filters[key] = remainingData;
          }
        }
      } else if(payload && payload.flag && payload.flag == 'removeAll') {
          if(state.businessFilters && state.businessFilters.filters) {
            for (let key in state.businessFilters.filters) {
              state.businessFilters.filters[key].length = 0;
            }
          } 
      } else{
        state.businessFilters = payload
      }
    },

    savebusinessHeaderFilters(state, payload) {
      if(payload.flag == 'push') {
        state.businessHeaderFilters.push(payload.filter)
      } else if (payload.flag == 'removeAll') {
        state.businessHeaderFilters = []
      } else {
        state.businessHeaderFilters = state.businessHeaderFilters.filter(f => f.name !== payload.filter.name);
      }
    },
     saveBusinessPrice(state, payload) {
      state.businessPrice = payload
    },
    
    resetState(state, payload) {
      if(payload.flag == 'general') {
        state.filters = null;
        state.headerFilters = [];
        state.price = null;
      } else {
        state.businessFilters = null;
        state.businessHeaderFilters = [];
        state.businessPrice = null;
      }
    }
    
};

const actions = {
  saveFilters(context, payload) {
    context.commit('saveFilters', payload);
  },

  saveHeaderFilters(context, payload) {
    context.commit('saveHeaderFilters', payload);
  },
  savePrice(context, payload) {
    context.commit('savePrice', payload);
  },

  // actions of business filters

  saveBusinessFilters(context, payload) {
     context.commit('saveBusinessFilters', payload);
  },

  savebusinessHeaderFilters(context, payload) {
    console.log(payload);
    context.commit('savebusinessHeaderFilters', payload);
  },

  saveBusinessPrice(context, payload) {
    context.commit('saveBusinessPrice', payload);
  },

  resetState(context, payload) {
    context.commit('resetState', payload);
  }

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};