<template>
    <div class="mfp-bg mfp-newsletter mfp-fadein-popup mfp-ready"></div>
    <div class="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-login-popup mfp-fade mfp-ready" tabindex="-1"
        style="overflow: hidden auto;">
        <div class="mfp-container mfp-ajax-holder">
            <div class="mfp-content">
                <div class="login-popup otp-modal white-popup" style="width:fit-content">
                    <div class="mb-6">
                        <h4 class="pl-1 mb-1 text-blue">Forgot Password ?</h4>
                        <p class="pl-1 mb-1">Enter your email address below and we’ll send you a code to reset your
                            password</p>
                    </div>
                    <form v-if="form" @submit.prevent="submit()" ref="form">
                        <div class="form-group pl-1" style="font-size: 1.4rem; width:500px">
                            <label>Email address *</label>
                            <input type="email" class="form-control text-blue" v-model="form.email" id="email">
                            <Error class="mt-1" v-if="errors" :message="errors"></Error>
                        </div>
                        <div class="d-flex justify-content-end">
                            <input class="btn btn-primary bg-orange mr-1 btn-rounded" 
                                        :class="{ 'opacity-25': form.processing}" :disabled="form.processing" type="submit" value="Submit">
                        </div>
                    </form>
                    <button title="Close (Esc)" type="button" class="mfp-close"
                        @click="$emit('closeForgotPwd')">×</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Helpers from '@/mixins/Helpers'
    import Error from './InputError.vue'
    export default {
        components: {
            Error
        },
        data() {
            return {
                form: {
                    email: ''
                },
                errors: null
            }
        },

        methods: {
            submit() {
                this.form.processing = true
                this.$http.post(`api/forgot-password`, this.form).
                    then(reponse => {
                        this.$toast.success('Otp code sent to your email')
                        this.$emit('closeForgotPwd')
                        this.emitter.emit('otp', {
                            type: 'forgot_password',
                            email: this.form.email
                        })
                    }).catch(e => {
                        this.errors = e.response.data.message
                        this.form.processing = false
                    })
            }
        },

        mixins: [Helpers]

    }
</script>

<style>

</style>