const state = {
  newsPaper: null 
};

const mutations = {
    newsPaper (state, payload) {
        state.newsPaper = payload
    }
};

const actions = {
  newsPaper(context, payload) {
    context.commit('newsPaper', payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};