const state = {
  all_businesses: [],
  level_two_tags: [],
  level_three_tags: [],
};

const mutations = {
    setAllBusinesses(state, payload) {
      state.all_businesses = payload
    },
    setLevelTwoTags(state, payload) {
      state.level_two_tags = payload
    },
    setLevelThreeTags(state, payload) {
      state.level_three_tags = payload
    }
};

const actions = {
    getAllBusinesses(context, payload) {
      context.commit('setAllBusinesses', payload);
    },
    getLevelTwoTags(context, payload) {
      context.commit('setLevelTwoTags', payload);
    },
    getLevelThreeTags(context, payload) {
      context.commit('setLevelThreeTags', payload);
    },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};