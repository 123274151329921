import { createStore } from 'vuex'

import authentication from './authentication';
import newsPaper from './newsPaper';
import settings from './settings';
import cart from './cart';
import userProfile from './userProfile';
import filters from './filters';
import pagination from './pagination';
import middleHeader from './middleHeader';

const store = createStore({
  modules: {
    authentication,
    newsPaper,
    settings,
    cart,
    userProfile,
    filters,
    pagination,
    middleHeader
  },
})

store.subscribe((mutation, state) => {
  if (mutation.type == "authentication/authUser") {
    localStorage.setItem('vuex', JSON.stringify(state.authentication))
  }
});
export default store;