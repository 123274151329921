const state = {
  cartCount: 0
};

const mutations = {
    saveCartCount (state, payload) {
        state.cartCount = payload
    }
};

const actions = {
  saveCartCount(context, payload) {
    context.commit('saveCartCount', payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};