<template>
    <button v-if="type" class="btn btn-primary bg-orange btn-rounded br-xs">
       <slot></slot>
    </button>
    <a v-else class="btn btn-primary btn-rounded" :class="{'btn-dark btn-outline' : btnType}">{{ value }}</a>
</template>

<script>
export default {
    props: ['btnType', 'value', 'type']
}
</script>

<style>

</style>