<template>
    <div ref="address_modal" class="white-popup mfp-hide" tabindex="-1">
        <div>
            <div class="mfp-login-popup">
                <div class="login-popup">
                    <div class="tab tab-nav-boxed tab-nav-center tab-nav-underline">
                        <div class="tab-content" style="border:none; width:100%;">
                            <div class="tab tab-nav-boxed tab-nav-center tab-nav-underline">
                        <tabs>
                            <li class="nav-item">
                                <a href="#sign-in" class="nav-link text-blue" @click="changeFormType('login')" :class="{'active' : formType == 'login'}">Sign In</a>
                            </li>
                            <li class="nav-item">
                                <a href="#sign-up" class="nav-link"  @click="changeFormType('register')" :class="{'active' : formType == 'register'}">Sign Up</a>
                            </li>
                        </tabs>
                        <div class="tab-content">
                            <div class="tab-pane" id="sign-in" :class="{'active in' : formType == 'login'}">
                                <Error v-if="errors" :message="errors"></Error>
                                <form v-if="form" @submit.prevent="submit()" ref="form">
                                    <div class="form-group login-input">
                                        <label>Email address <span class="text-danger">*</span></label>
                                        <input type="email" class="form-control text-blue" v-model="form.email" id="email">
                                    </div>
                                    <div class="form-group login-input mb-0">
                                        <label>Password <span class="text-danger">*</span></label>
                                        <input :type="showPassword ? 'text' : 'password'" class="form-control text-blue input-password" v-model="form.password" name="password" id="password">
                                        <span class="eye-password" @click="toggleShowPassword">
                                            <i :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'" style="font-size: 24px; !importnat"></i>
                                        </span>
                                    </div>
                                    <div class="form-checkbox d-flex align-items-center justify-content-between">
                                        <a href="#" class="text-orange" @click="forgotPassword()">Lost your password?</a>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <Button  :type="'submit'" :class="processing ? 'opacity-25' : ''" :disabled="processing">
                                            <div v-if="processing" class="loader"></div>
                                            <span v-else>Sign In</span>
                                        </Button>
                                    </div>
                                </form>
                            </div>
                            <div class="tab-pane" id="sign-up" :class="{'active in' : formType == 'register'}">
                                <Error v-if="registerErrors" :message="registerErrors"></Error>
                                <form v-if="form" @submit.prevent="submit(false)" ref="form">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="form-group">
                                            <label>First Name <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control text-blue" v-model="form.first_name" id="email_1" >
                                        </div>
                                        <div class="form-group p-2">
                                            <label>Last Name <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control text-blue" v-model="form.last_name" id="email_1" >
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="form-group">
                                            <label>Email address <span class="text-danger">*</span></label>
                                            <input type="email" class="form-control text-blue" v-model="form.email" id="email_1" >
                                        </div>
                                        <div class="form-group mb-5">
                                            <label>Password <span class="text-danger">*</span></label>
                                            <input type="password" class="form-control text-blue" v-model="form.password"  id="password_1">
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="form-group mb-5">
                                            <label>Confirm Password <span class="text-danger">*</span></label>
                                            <input type="password" class="form-control text-blue" v-model="form.password_confirmation"  id="password_1">
                                        </div>
                                        <div class="form-group mb-5">
                                            <label>Phone Number <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control text-blue" v-model="form.phone" id="password_1">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <Button :type="'submit'" :class="processing ? 'opacity-25' : ''" :disabled="processing">
                                            <div v-if="processing" class="loader"></div>
                                            <span v-else>Sign Up</span>
                                        </Button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button title="Close (Esc)" type="button" class="mfp-close">×</button>
    </div>
</template>

<script>
    import Button from './Button.vue'
    import Tabs from './Tabs.vue'
    import Error from './InputError'
    import Helpers from "@/mixins/Helpers";
    import { mapState, mapMutations } from 'vuex';
    export default {
        props: ['type'],
        components: {
            Button,
            Tabs,
            Error
        },
        data() {
            return {
                form: null,
                errors: null,
                registerErrors: null,
                formType: this.type,
                processing: false,
                deviceToken: null,
                showPassword: false,
            }
        },
        methods: {
            async  submit() {
                this.processing = true
                var route = this.formType == 'register' ? 'signup' : 'signin'
                var data = this.form  
                const userAgent = window.navigator.userAgent;
                let deviceType = '';

                // Check if the user agent string contains "Mobile" or "Tablet"
                if (/Mobile|Tablet/i.test(userAgent)) {
                        deviceType = 'Mobile or Tablet';
                } else {
                    deviceType = 'Desktop';
                }
                // Get the device name from the user agent string
                const deviceName = userAgent.match(/\((.*?)\)/)[1];
                // login api

                this.$http.get('/sanctum/csrf-cookie').then(response => {
                    this.$http.post('/api/'+route, data, {
                        headers: {
                            'Device-Token': localStorage.getItem('firebase_token') ? localStorage.getItem('firebase_token') : null,
                            'Device-Type': deviceType,
                            'Device-Name': deviceName,
                            'Accept-Language': 'en',
                            'Send-Notifications': 1,
        
                        }
                    })
                    .then(response => {
                        this.processing = false
                        localStorage.setItem('accessToken', response.data.data.access_token);
                        this.popup.close();
                        this.$store.dispatch('authentication/authUser', response.data.data)
                        this.emitter.emit("close", {
                            user: response.data.data,
                        });
                        this.emitter.emit('cart')

                        if(this.formType == 'register' || !response.data.data.email_verified_at) {
                            this.emitter.emit('otp', {
                                type: 'registration',
                            })
                        }
                        this.emitter.emit('user-logged-in')
                        this.emitter.emit('set-location')
                        this.emitter.emit('default-address')
                        this.emitter.emit('close-dropdown')
                        this.$toast.success("Login Successfully")
                    })
                    .catch(e => {
                        this.processing = false
                        console.log(e);
                        if (this.formType == 'register') {
                            this.registerErrors = e.response.data.message
                        } else {
                            this.errors = e.response.data.message
                        }
                    })
                })
            },

            changeFormType(type) {
                this.formType = type
            },

            forgotPassword() {
                this.popup.close();
                this.emitter.emit('forgot-password')
            },

            toggleShowPassword() {
                this.showPassword = !this.showPassword;
            },
        },

        mounted() {
            this.emitter.on("open-login-modal", (args) => {
                this.formType = args.type
                if(this.formType == 'register') {
                    this.form = {
                        first_name: '',
                        last_name: '',
                        phone: '',
                        email: '',
                        password: '',
                        password_confirmation: ''
                    }
                } else {
                    this.form = {
                        email: '',
                        password: ''
                    }
                }
                setTimeout(() => {
                    if (this.$refs.address_modal != null) {
                        this.popup = window.Wolmart.popup({
                            items: {
                                src: this.$refs.address_modal,
                                type: 'inline'
                            },
                            mainClass: "mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-login-popup mfp-fade mfp-ready",
                            modal: true,
                            showCloseBtn: false,
                            callbacks: {
                                close: function () {
                                    this.errors = null;
                                    this.registerErrors = null;
                                }.bind(this)
                            }
                        })
                    }
                }, 100)
            })
        },
        mixins: [Helpers],
    }
</script>

<style>

</style>