const state = {
  settings: null 
};

const mutations = {
    storeSettings (state, payload) {
        state.settings = payload
    }
};

const actions = {
  storeSettings(context, payload) {
    context.commit('storeSettings', payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};