import moment from 'moment';
export default {
    methods: {

        // get selected track
        getUserData() {
            if (localStorage.vuex) {
                let authUser = JSON.parse(localStorage.vuex)
                return authUser.user
            }
        },
        
        slugToName(name){
            return name.replaceAll('-', ' ');
        },
        // formate time
        formatDate(dateString) {
            return moment(dateString).format("Do MMM YYYY");
        },
        // formate date & time
        formatDateTime(dateString) {
            return moment(dateString).format("Do MMM YYYY h:mm:ss A");
        },
        // review data formt
        formatReviewDate(dateString) {
            return moment(dateString).format("ddd MMM DD, YYYY [at] HH:mm a");
        },
        //convert minutes and seconds in minutes
        convertToMinutes(timeString){
            var moment = require('moment');
            moment.updateLocale('en', {
                relativeTime : {
                    future: "in %s",
                    past:   "%s ago",
                    s:  "seconds",
                    m:  "1 minute", 
                    h:  "1 hour", 
                    d:  "1 day",   
                    M:  "1 month",  
                    y:  "1 year",
                }
            });
            timeString = timeString.replace(':', ' ');
            if(Number(timeString.split(' ')[0]) > 0){
                return moment.duration(moment(timeString, 'DD HH:mm').format('DD HH:mm')).humanize();
            }
            return moment.duration(moment(timeString.split(' ')[1], 'HH:mm').format('HH:mm')).humanize();
        },
        // media
        getImage(path) {
            if (path == null){
                return process.env.VUE_APP_BASE_URL + 'media/images/placeholder.png'
            } else if (path.includes('http')) {
                return path;
            } else {
                return process.env.VUE_APP_BASE_URL + path;
            }
        },

        getRatings(avg_rating) {
            return (avg_rating / 5) * 100
        },

        splitText(text) {
            return text.replaceAll('_', ' ');
        },

        numberFormat(number) {
            var settings = this.$store.state.settings.settings
            var separator
            var decimal_length
            if(settings) {
                settings = JSON.parse(settings)
                settings.forEach((value, index) => {
                    if(value.key == 'decimal_length') {
                        decimal_length = value.value
                    } else if(value.key == 'decimal_separator'){
                        separator = value.value
                    }
                })
            }
            var newnumber =  number.toFixed(decimal_length)
            newnumber = newnumber.replace(".", separator)
            return newnumber
        },
        numberDeformat(number) {
            var settings = this.$store.state.settings.settings
            var decimal_length
            var separator
            if(settings) {
                settings = JSON.parse(settings)
                settings.forEach((value, index) => {
                    if(value.key == 'decimal_length') {
                        decimal_length = value.value
                    } else if(value.key == 'decimal_separator'){
                        separator = value.value
                    }
                })
            }
            number = number+"";
            newnumber = Number(number.replace(separator,"."))
            var newnumber =  newnumber.toFixed(decimal_length)
            return newnumber
        },
        inValidToken(message) {
            if(message == 'Unauthenticated.')
            {
                localStorage.removeItem('vuex');
                localStorage.removeItem('accessToken')
                this.emitter.emit('invalid-token', {   
                })
                this.emitter.emit("addToCart", {
                });
                this.emitter.emit('open-login-modal', {
                    type: 'login'
                })
                localStorage.setItem('LS_ROUTE_KEY', this.$route.name)
                // this.$router.push('/');
            }
        },
        getModuleValue(){
            return localStorage.getItem('selectedModule');
        },
        getModuleName(){
            return localStorage.getItem('selectedModuleName');
        },
        otpNotVerified(status) {
            if(status == 403) {
                this.emitter.emit('otp', {
                    type: 'login',
                })
            }
        },
        orderStatusChanger(order) {
            return  order.replaceAll('_', ' ');
        },
        pictureLoadingError(event) {
            event.target.src = "media/images/placeholder.png"
        },
        shortStoreName(name) {
            var name = this.slugToName(localStorage.getItem('store'))
            var store = name.split(" ")
            if(store.length > 2) {
                return store['0'] + ' ' + store['1']
            } else {
                return name
            }
        },

        shortSelectedTagName(name, type) {
            name = "retailretailretialretailretailretail"
            return name
        },


        uniqueColors(variants) {
            const uniqeColors = [...new Set(this.singleProduct.variants.map(item => JSON.stringify({ color: item.color, custom_color: item.custom_color })))].map(item => JSON.parse(item));
            return uniqeColors
        },

        stripePaymentFormat(amount){
            return amount/100
        },

        limit(string, limit = 90, end = '...') {
            return (string?.length > limit
                ? string.slice(0, limit) + end
                : string)
        }
    }
}