const state = {
  userProfile: null 
};

const mutations = {
    storeUser (state, payload) {
        state.userProfile = payload
    }
};

const actions = {
  storeUser(context, payload) {
    context.commit('storeUser', payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};