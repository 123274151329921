<template>
    <!-- Start of Header -->
    <div class="w-100 bg-gray">
        <!-- <header class="header container p-0" id="header">
        <div class="header-top">
            <top-header></top-header>
        </div>
        <div class="sticky-content-wrapper">
            <div class="sticky-content fix-top sticky-header">
            </div>
        </div> 
    </header>-->
    </div>
    <!-- End of Header -->
</template>

<script>
// import TopHeader from '@/components/TopHeader.vue'
// import Logo from '@/components/Logo.vue'
//import SearchBar from '@/components/SearchBar.vue'
// import ShopByProduct from '@/components/ShopByProduct.vue'
// import ShopByStore from '@/components/ShopByStore.vue'
// import ShopByCategory from '@/components/ShopByCategory.vue'
// import TagSlider from '@/components/TagSlider.vue'
// import FilterSlider from '@/components/FilterSlider.vue'
// import MiddleHeader from '@/components/MiddleHeader.vue'

import Helpers from '@/mixins/Helpers'
    export default {
        components: {
            // TopHeader,
            // Logo,
            // SearchBar,
            // ShopByProduct,
            // TagSlider,
            // ShopByStore,
            // ShopByCategory,
            // FilterSlider,
            // MiddleHeader
        },
        data() {
            return {
                errors: [],
                width: window.innerWidth,
                moduleTags: null,
                tags: [],
                selectedTags: [],
                loading: false,
                showStoreMenu: false,
                showProductMenu: false,
                activeIndex: -1,
                is: 'homePage',
                levels: 0,
                pageName: null,
                filters: [],
                firstElement: null,
                route: this.$route,
            }
        },
        beforeMount(){
            // this.verifyHierarchy()
        },
        mounted(){
            // this.emitter.on("module-changed", (args) => {
            //     this.selectedModule = args
            // });

            // document.addEventListener("click", this.closeMenu);
            // this.emitter.on('category-modal', (args) => {
            //     this.openMenu(true, args.name == 'Filter Stores' ? 'store' : 'product');
            // })

            // //getting filters
            // this.emitter.on('send_filters_to_header', (args) => {
            //     if (args.remove == false) {
            //         if (this.filters.includes(args.filter)) {
            //             this.filters = this.filters.filter(function (filter) {
            //                 return filter.id != args.filter.id
            //             })
            //         } else {
            //             this.filters.push(args.filter)
            //         }
            //     } else {
            //         this.filters.pop()
            //     }
            // })
            // this.emitter.on('tag-added-without-processing', (args) => {
            //     if(!args.tag){
            //         this.route = null
            //     }
            //     this.firstElement = args.tag
            // })

            // this.emitter.on('update-tag-slider', ()=>{
            //     this.callApi(this.route)
            // })


            // this.emitter.on('tag-added', (args)=>{
            //     this.selectedTags = args.tags
            // })


            // this.emitter.on('empty-tags', ()=>{
            //     this.selectedTags = []
            // })
            // //clear filter slider on clear all button
            // this.emitter.on('cleanTagFilter', () => {
            //     this.filters = []
            // })
        },
        methods: {
            getSettings() {
                console.log('hr');
                this.$http.get('/api/settings')
                .then(response => {
                    localStorage.setItem('settings', JSON.stringify(response.data.data.settings));
                    this.emitter.emit('settings', response.data);
                })
                .catch(errors => {
                    this.errors.push(errors)
                })
            },
            clearSearch(){
                this.emitter.emit("clear-search");
            },
            async getTagsWithLevel(url, params){
                this.loading = true;
                url ? await this.$http.get(url, {
                        params : params
                    })
                    .then(response => {
                        this.loading = false;
                        this.tags = response.data.data
                    })  
                    .catch(e => {
                        this.loading = false;
                        this.errors = e.response.data.message
                    }) : null
            },
            getApiUrl(route = null){
                let urlAndParams = {url: '', params: {}}
                if(route.fullPath.includes('vendor-store') || route.fullPath.includes('vendor-detail')){
                    urlAndParams = {
                        url: `api/${this.getModuleValue()}/business/${route.params.slug}/product-tags`,
                        params: {level_three_tag: route.params.levelThreeslug}
                    }
                }else if(route.fullPath.includes('product')){
                    return null
                }
                else{
                    if(route.params.levelFourslug || route.params.levelThreeslug){
                        urlAndParams = { 
                            url: `api/${this.getModuleValue()}/productTags/${route.params.levelTwoslug}`, 
                            params: {level_three_tag:route.params.levelThreeslug }
                        }
                    }else if(route.params.levelTwoslug){
                        urlAndParams = {url: `api/${this.getModuleValue()}/productTags`, params: {
                            level_two_tag: route.params.levelTwoslug
                        }}
                    }else{
                        urlAndParams = {url: `api/${this.getModuleValue()}/industry-tags`, params: {}}
                    }
                }
                return urlAndParams;
            },
            callApi(route=null){
                // getting api url and getting tags in tagSlider
                let API = this.getApiUrl(route)
                // getting api url and getting tags in tagSlider
                // if(API)
                // this.getTagsWithLevel(API.url, API.params)
            },
            openMenu(flag = false, type) {
                // window.scrollTo({ top: 0, behavior: 'smooth' });
                if (type == 'product') {
                    flag ? setTimeout(() => {
                        this.showProductMenu = !this.showProductMenu
                        this.showStoreMenu = false
                    }, 500) : this.showProductMenu = !this.showProductMenu
                } else{
                    flag ? setTimeout(() => {
                        this.showStoreMenu = !this.showStoreMenu
                        this.showProductMenu = false
                    }, 500) : this.showStoreMenu = !this.showStoreMenu
                }
            },
            closeMenu(event) {
                if (event.target.id != 'shop-by-cat-identifier' && event.target.id != 'filter-store-identifier') {
                    this.showProductMenu = false
                }
                if (event.target.id != 'shop-by-store-identifier' && event.target.id != 'filter-product-identifier') {
                    this.showStoreMenu = false
                }
            },
            verifyHierarchy(){
                if(this.$route.params.levelTwoslug || (this.$route.params.levelThreeslug && this.$route.fullPath.includes('vendor-store'))){
                    this.$http.get(`api/${this.getModuleValue()}/verify-hierarchy/`, {
                        params: {
                            slug: this.$route.params.slug ? this.$route.params.slug : null,
                            level_two_tag: this.$route.params.levelTwoslug ? this.$route.params.levelTwoslug : null,
                            level_three_tag: this.$route.params.levelThreeslug ? this.$route.params.levelThreeslug : null,
                            level_four_tag: this.$route.params.levelFourslug ? this.$route.params.levelFourslug : null,
                        }    
                    }).then(response => {
                        //if we do not get the response, the page will be redirected to 404
                    }).catch(errors => {
                        this.$router.push('/404');
                    })

                }
            }
        },
        watch: {
            // 'selectedTags': {
            //     handler(tags){
            //         if(this.selectedTags.length == 3){
            //             this.activeIndex = this.tags.findIndex(x => x.slug === this.selectedTags[2].slug);
            //         }else if(this.selectedTags.length == 2 && this.route?.name == 'Store-level-four'){
            //             this.activeIndex = this.tags.findIndex(x => x.slug === this.selectedTags[1].slug);
            //         }
            //         else{
            //             this.activeIndex = -1
            //         }
            //     }, deep: true
            // },
            '$route': {
                handler(route){
                    if((!route.params.levelFourslug || this.tags.length == 0 || route.params.levelThreeslug != this.route.params.levelThreeslug) && !(route.fullPath.includes('product'))){
                        this.callApi(route)
                    }else{
                        //Setting tag active
                        this.activeIndex = this.tags.findIndex(x => x.slug === route.params.levelFourslug);
                    }
                    this.route = route
                    // Getting business name for showing it in search bar if user is coming directly to this page using url
                    if(route.params.slug && route.params.levelThreeslug && !this.firstElement && route.fullPath.includes('vendor-store')){
                        this.$http.get(`api/${this.getModuleValue()}/businesses/${route.params.slug}`, {
                        }).then(response => {
                            this.emitter.emit('business-name', {
                                business: response.data.data.name
                            })
                            this.firstElement = {id:response.data.data.uuid, name: response.data.data.name}
                        })
                    }
                    this.pageName = route
                }, deep: true,
                immediate: true
            },  
            filters: {
                handler(filter){
                    this.filters = filter
                },
                deep: true
            },
        },
        mixins: [ Helpers ]
    }
</script>