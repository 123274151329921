import { createApp } from 'vue';
import App from './App.vue';
window.$ = window.jQuery = require('jquery')
import Swiper from 'swiper/swiper-bundle.min.js';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import Toaster from "@meforma/vue-toaster";
import easySpinner from 'vue-easy-spinner';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
var jQuery = window.$;
import noUiSlider from 'nouislider';
import mitt from 'mitt';
require('./assets/js/main.js');
import router from './router/index'; // <---
import store from './stores/index'

import apiClient from '@/axios';

const app = createApp(App)

app.use(router)
app.use(store)
app.use(Toaster)
app.use(VueSweetalert2)
app.use(easySpinner, {
    /*options*/
    prefix: 'easy',
})
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyB6_25wMJBgEeLqkRTBxC0aCXppPeXXCdQ',
        libraries: "places,drawing"
    },
    installComponents: true
});

app.config.globalProperties.emitter = mitt()
app.config.globalProperties.$vapKey = process.env.VUE_APP_Fire_Base_KEY
const base_url = process.env.VUE_APP_BASE_URL;
app.config.globalProperties.$env = { base_url };

app.config.globalProperties.$http = apiClient;
app.config.globalProperties.$api_key = process.env.VUE_APP_API_KEY;
app.config.globalProperties.$api_url = process.env.VUE_APP_API_URL;
// Replace -> app.mount('#app')
router.isReady().then(() => {
    app.mount('#app')
})
// app.mount('#app')

