<template>
    <ul class="nav nav-tabs" role="tablist" :class="{'justify-content-center' : !type}" style="border-bottom: 1px solid #eee;">
        <slot></slot>
    </ul>
</template>

<script>
    export default {
        props: ['type']
    }
</script>

<style>

</style>