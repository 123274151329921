import axios from 'axios';

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL, // Replace with your Laravel API URL
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

apiClient.interceptors.request.use(function (config) {
    const apiKey = process.env.VUE_APP_API_KEY ; // Replace with your API key
    config.headers['x-api-key'] = apiKey;
    return config;
});

export default apiClient;
